import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export const useRefreshFromWorkOs = ({ statusMessage, userId }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params) => ApiClient.post(`/users/${userId}/refresh_from_workos`, params),
    enabled: !!userId,
    onSuccess: async () => {
      await queryClient.invalidateQueries('admin')
      await queryClient.invalidateQueries('caregiver')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'User refreshed!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useRefreshFromWorkOs
