import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import moment from 'moment-timezone'

export function formatOccurrences(tarTasks, timezone) {
  if (!tarTasks) return []

  return tarTasks.map((tar) => {
    const occurrences = tar.occurrences.reduce((acc, occurrence) => {
      const baseTime = occurrence.expectedAt || occurrence.actionedAt
      const date = moment(baseTime).tz(timezone).format(momentFormats.date)
      if (!acc[date]) acc[date] = []
      acc[date].push(occurrence)
      return acc
    }, {})
    return { ...tar, occurrences }
  })
}

export function sortTarTasks(tarTasks) {
  return tarTasks.sort(
    (a, b) => (a.treatment?.pharmacyInstructions || '').localeCompare(b.treatment?.pharmacyInstructions || ''),
  )
}

// eslint-disable-next-line import/prefer-default-export
export function tarTasksColumns(
  {
    dateRange,
    treatmentTemplate,
    taskTemplate,
    occurrenceTemplate,
    showTime = true,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'treatment.id',
      header: 'Treatment',
      body: treatmentTemplate,
      style: { minWidth: '18rem', width: '18rem', maxWidth: '18rem' },
    },
    {
      field: 'task.id',
      header: 'Task',
      body: taskTemplate,
      style: { minWidth: '20rem', width: '20rem', maxWidth: '20rem' },
    },
  ]

  if (showTime) {
    columns.push({
      field: 'time',
      header: 'Time',
      style: { minWidth: '8rem', width: '8rem', maxWidth: '8rem' },
    })
  }

  days.forEach((day) => {
    columns.push({
      field: `occurrences.${day.format(momentFormats.date)}`,
      header: day.format('MMM Do'),
      body: occurrenceTemplate,
      style: { minWidth: '10rem', width: '10rem', maxWidth: '10rem' },
    })
  })

  return columns
}
