import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Toast } from 'primereact/toast'
import Exports from '@components/patients/Exports'
import { TaskConditionsCard } from '@components/tasks/TaskConditionsCard'
import { TaskTable } from '@components/tasks/TaskTable'
import TreatmentTable from '@components/treatments/TreatmentTable'
import { useTreatment } from '@hooks/treatments'

function ManageTreatmentPage({ handleSetHeader, patient }) {
  const scheduleStatusMessage = useRef(null)
  const { patientId: clientId, treatmentId } = useParams()

  const {
    data: treatment,
    isLoading,
  } = useTreatment({ treatmentId })

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  if (isLoading || isEmpty(treatment) || isEmpty(patient)) {
    return 'Loading...'
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  const tasks = treatment.tasks || []
  const showTaskConditions = treatment.onboardedAt && tasks.length === 1

  return (
    <>
      <Toast ref={scheduleStatusMessage} />
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      {/* TODO: TreatmentHeader component with treatment lifecycle actions */}
      <TreatmentTable
        treatment={treatment}
        timezone={patient.timezone}
        canEdit
      />
      {
        treatment.onboardedAt && tasks.map((task) => (
          <TaskTable
            key={task.id}
            task={task}
            timezone={patient.timezone}
          />
        ))
      }
      {
        showTaskConditions && (
          <TaskConditionsCard task={tasks[0]} />
        )
      }
      {/* TODO: Superadmin PharmacyOrders component for the treatment orders */}
    </>
  )
}

export default ManageTreatmentPage
