import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export const useCreateTask = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params) => {
      const task = {
        patientId: params.patientId,
        treatmentId: params.treatmentId,
        taskType: params.taskType,
        taskCategory: params.taskCategory,
        taskAction: params.taskAction,
        startAt: params.startAt,
        endAt: params.endAt,
        title: params.title,
        description: params.description,
        showOnTar: params.showOnTar,
      }

      return ApiClient.post('/tasks', task)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('tasks')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Task Created!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useCreateTask
